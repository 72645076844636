import React, { useEffect, useState } from "react";

import "./index.scss";

const Footer = () => {
  return (
    <div id="footer">
      <div className="content">
        <p>Designed by CritiColl</p>
        <p>Patent Pending</p>
      </div>
    </div>
  );
};

export default Footer;
