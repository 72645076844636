import React, { useState, FormEvent } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LogoWhite from "../../assets/images/logo-white.svg";
import TrailLeft1 from "../../assets/images/Subscribe/trail-left-1.svg";
import TrailLeft2 from "../../assets/images/Subscribe/trail-left-2.svg";
import TrailRight1 from "../../assets/images/Subscribe/trail-right-1.svg";
import TrailRight2 from "../../assets/images/Subscribe/trail-right-2.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "./index.scss";
import { subscribeEmail } from "../../api/subscribe";

const MailForm = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const submitEmail = (e: any) => {
    e.preventDefault();
    subscribeEmail(email, setLoading);
  };

  return (
    <div id="subscribePage">
      <div className="header">
        <a href="/welcome">
          <ArrowBackIcon className="back-icon" />
        </a>
        <a href="/welcome" className="box-logo">
          <img src={LogoWhite} alt="logo" />
        </a>
        <div className="box-logo" />
      </div>
      <img className="trail trail-left-1" src={TrailLeft1} alt="trail" />
      <img className="trail trail-left-2" src={TrailLeft2} alt="trail" />
      <img className="trail trail-right-1" src={TrailRight1} alt="trail" />
      <img className="trail trail-right-2" src={TrailRight2} alt="trail" />
      <div className="content">
        <h2 className="form-title">Subscribe</h2>
        <p className="form-subtitle">
          The app that guides you turn-by-turn on your favorite trails. Enjoy
          the ease of <span>HANDS-FREE</span> voice navigation and leave your
          map at home!
          <br />
          <br />
          Join to receive <span>EARLY</span> access to TrailChat’s exclusive
          beta launch. Subscribe your email and get <span>FREE</span> premium
          access.
        </p>
        <form onSubmit={(e) => submitEmail(e)}>
          <TextField
            required
            type="email"
            className="form-input"
            id="filled-basic"
            placeholder="Enter Your Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            className="form-btn"
            variant="outlined"
            placeholder="Enter Your Email Address"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress className="icon-loading" />
            ) : (
              "Claim Offer"
            )}
          </Button>
          <span className="info-text">
            <CheckCircleOutlineIcon />
            No credit card required
          </span>
        </form>
      </div>
    </div>
  );
};

export default MailForm;
