import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import SoundIcon from "../../../assets/icons/Home/Features/sound.svg";
import PhoneIcon from "../../../assets/icons/Home/Features/phone.svg";
import TrailRight from "../../../assets/icons/Home/Features/trail-right.svg";
import TrailLeft from "../../../assets/icons/Home/Features/trail-left.svg";
import BreadcrumbsIcon from "../../../assets/icons/Home/Features/breadcrumbs.svg";
import ReviewIcon from "../../../assets/icons/Home/Features/review.svg";
import Offlinecon from "../../../assets/icons/Home/Features/offline.svg";
import Performancecon from "../../../assets/icons/Home/Features/performance.svg";
import BottomLine from "../../../assets/images/Home/dashed-bottom-line.svg";
import SideLine from "../../../assets/images/Home/dashed-side-line.svg";

import "./index.scss";

const SafetyFeatures = () => {
  return (
    <div id="features">
      <h3 className="section-title">Revolutionize Your Trail Experience</h3>
      {/* <p className="section-subtitle">TrailChat’s features help you find your ideal trails and enjoy them without needing a map!</p> */}
      <div className="content-features">
        <div className="box-items">
          <div className="half-items">
            <div className="item">
              <div className="box-icon">
                <img src={SoundIcon} className="icon" />
              </div>
              <p className="item-title">
                Enjoy the View with Turn-by-turn Guidance
              </p>
              <p className="item-text">
                Listen to the directions and never stop to check your map again.
              </p>
            </div>
            <img className="side-line" src={SideLine} />
            <div className="item">
              <div className="box-icon">
                <img src={PhoneIcon} className="icon" />
              </div>
              <p className="item-title">Ride with Safety</p>
              <p className="item-text">
                Feel secure with turn-by-turn directions and emergency tools.
              </p>
            </div>
            <img className="side-line" src={SideLine} />
            <div className="item">
              <div className="box-icon">
                <img src={BreadcrumbsIcon} className="icon" />
              </div>
              <p className="item-title">Find Your Way Back with Breadcrumbs</p>
              <p className="item-text">
                Backtrack your steps so you can turn around back to safety.
              </p>
            </div>
          </div>
          <img className="bottom-line" src={BottomLine} />
          <div className="half-items">
            <div className="item">
              <div className="box-icon">
                <img src={ReviewIcon} className="icon" />
              </div>
              <p className="item-title">Discover New Trails</p>
              <p className="item-text">
                Search, preview, and find beautiful trails near you.
              </p>
            </div>
            <img className="side-line" src={SideLine} />
            <div className="item">
              <div className="box-icon">
                <img src={Offlinecon} className="icon" />
              </div>
              <p className="item-title">
                Access Your Trails Anywhere. Anytime.
              </p>
              <p className="item-text">
                Navigate your trails even if you are offline.
              </p>
            </div>
            <img className="side-line" src={SideLine} />
            <div className="item">
              <div className="box-icon">
                <img src={Performancecon} className="icon" />
              </div>
              <p className="item-title">Capture Your Performance</p>
              <p className="item-text">Keep track of your progress.</p>
            </div>
          </div>
        </div>
        <Button
          href="/subscribe"
          type="submit"
          className="claim-btn"
          variant="contained"
        >
          Claim Offer
        </Button>
      </div>
      <img className="trail trail-left" src={TrailLeft} alt="Trail" />
      <img className="trail trail-right" src={TrailRight} alt="Trail" />
    </div>
  );
};

export default SafetyFeatures;
