import React, { useEffect, useState } from "react";

import AppButton from '../../../components/Button';

import ImgCard1 from '../../../assets/images/Home/associations-1.jpg';
import ImgCard2 from '../../../assets/images/Home/associations-2.jpg';
import CardStar from '../../../assets/icons/Home/Associations/green-star.svg';
import Trail from '../../../assets/icons/Home/Associations/trail.svg';
import './index.scss';


const Associations = () => {
    return (
        <div id="associations">
            <h2 className="section-title">Associations
                <span className="soon-text">Coming Soon</span>
            </h2>
            <h3 className="section-subtitle">Upload your association’s trails with hands-free step-by-step directions</h3>
            <div className="content">
                <div className="content-cards">
                    <div className="card">
                        <div className="box-img">
                            <img src={ImgCard1} alt="Card Image" />
                        </div>
                        {/* <div className="card-rating">
                            <p>Circuit | </p>
                            <img src={CardStar} alt="Star Rating" />
                            <span>4.8</span>
                            <p>(24)</p>
                        </div> */}
                        <h4 className="card-title">Colorado Trail,</h4>
                        <p><strong>Denver, CO</strong> | Length: 486 miles</p>
                        <p className="card-description">
                            Description: The Colorado Trail is a long-distance trail running for 486 miles.
                        </p>
                    </div>
                    <div className="card">
                        <div className="box-img">
                            <img src={ImgCard2} alt="Card Image" />
                        </div>
                        {/* <div className="card-rating">
                            <p>Circuit | </p>
                            <img src={CardStar} alt="Star Rating" />
                            <span>4.8</span>
                            <p>(24)</p>
                        </div> */}
                        <h4 className="card-title">Cascade Canyon Trail,</h4>
                        <p><strong>Grand Teton, WY</strong> | Length: 13.6 miles</p>
                        <p className="card-description">
                            Description: The Cascade Canyon Trail is a 13.6-mile round-trip long hiking trail in Grand Teton National Park.
                        </p>
                    </div>
                </div>
                <div className="content-info">
                    <ul>
                        <li className="item-info">Virtually upload your events in seconds</li>
                        <li className="item-info">Members digitally sign forms and waivers</li>
                        <li className="item-info">Reach a wider audience to purchase daily or annual memberships</li>
                    </ul>
                    <div className="box-btn">
                        <AppButton outlined={false} label="Register your association" />
                    </div>
                </div>
            </div>
            {/* <img className="trail" src={Trail} alt="Trail" /> */}
        </div>
    );
};

export default Associations;