import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LogoWhite from "../../assets/images/logo-white.svg";
import TrailLeft1 from "../../assets/images/Subscribe/trail-left-1.svg";
import TrailLeft2 from "../../assets/images/Subscribe/trail-left-2.svg";
import TrailRight1 from "../../assets/images/Subscribe/trail-right-1.svg";
import TrailRight2 from "../../assets/images/Subscribe/trail-right-2.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet";

import "./index.scss";
const ThankYou = () => {
  return (
    <div id="subscribePage">
      <Helmet>
        {/* <script>
          {`
             gtag('event', 'conversion', {'send_to': 'AW-10993795724/SUsWCLvCtOMDEIyFoPoo'});
          `}
        </script> */}
      </Helmet>

      <div className="header">
        <a href="/welcome">
          <ArrowBackIcon className="back-icon" />
        </a>
        <a href="/welcome" className="box-logo">
          <img src={LogoWhite} alt="logo" />
        </a>
        <div className="box-logo" />
      </div>
      <img className="trail trail-left-1" src={TrailLeft1} alt="trail" />
      <img className="trail trail-left-2" src={TrailLeft2} alt="trail" />
      <img className="trail trail-right-1" src={TrailRight1} alt="trail" />
      <img className="trail trail-right-2" src={TrailRight2} alt="trail" />
      <div className="content">
        <h2 className="form-title">
          Success <CheckCircleIcon className="checkIcon" />
        </h2>
        <p className="form-subtitle">
          Email successfully registered.
          <br />
          <br />
          Thank you for signing up and becoming a Trailblazer. Keep an eye out
          on your email for more information!
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
