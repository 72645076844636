import React, { useState, FormEvent } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import ImgCard1 from "../../../assets/images/Home/associations-1.jpg";
import ImgCard2 from "../../../assets/images/Home/associations-2.jpg";

import "./index.scss";

const Sites = () => {
  const [filter, setFilter] = useState<string>("recent");

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value);
  };

  return (
    <div id="sites-camping">
      <p className="title">Search TrailChat's Partner Camping Sites</p>
      <span className="divider" />
      <div className="header-sites">
        <p className="text-header">Check out some camping sites</p>
        <div className="order">
          <FormControl fullWidth size="small">
            <InputLabel id="order-select">Order</InputLabel>
            <Select
              labelId="order-select"
              id="order-select"
              value={filter}
              label="Order"
              onChange={handleChange}
            >
              <MenuItem value={"recent"}>Most Recent</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="content-sites">
        <div className="card">
          <div className="box-img">
            <img src={ImgCard1} alt="Card Image" />
          </div>
          <h4 className="card-title">Colorado Trail,</h4>
          <p>
            <strong>Denver, CO</strong> | Length: 486 miles
          </p>
          <p className="card-description">
            Description: The Colorado Trail is a long-distance trail running for
            486 miles.
          </p>
        </div>
        <div className="card">
          <div className="box-img">
            <img src={ImgCard2} alt="Card Image" />
          </div>
          <h4 className="card-title">Cascade Canyon Trail,</h4>
          <p>
            <strong>Grand Teton, WY</strong> | Length: 13.6 miles
          </p>
          <p className="card-description">
            Description: The Cascade Canyon Trail is a 13.6-mile round-trip long
            hiking trail in Grand Teton National Park.
          </p>
        </div>
        <div className="card">
          <div className="box-img">
            <img src={ImgCard1} alt="Card Image" />
          </div>
          <h4 className="card-title">Colorado Trail,</h4>
          <p>
            <strong>Denver, CO</strong> | Length: 486 miles
          </p>
          <p className="card-description">
            Description: The Colorado Trail is a long-distance trail running for
            486 miles.
          </p>
        </div>
        <div className="card">
          <div className="box-img">
            <img src={ImgCard2} alt="Card Image" />
          </div>
          <h4 className="card-title">Cascade Canyon Trail,</h4>
          <p>
            <strong>Grand Teton, WY</strong> | Length: 13.6 miles
          </p>
          <p className="card-description">
            Description: The Cascade Canyon Trail is a 13.6-mile round-trip long
            hiking trail in Grand Teton National Park.
          </p>
        </div>
        <div className="card">
          <div className="box-img">
            <img src={ImgCard1} alt="Card Image" />
          </div>
          <h4 className="card-title">Colorado Trail,</h4>
          <p>
            <strong>Denver, CO</strong> | Length: 486 miles
          </p>
          <p className="card-description">
            Description: The Colorado Trail is a long-distance trail running for
            486 miles.
          </p>
        </div>
        <div className="card">
          <div className="box-img">
            <img src={ImgCard2} alt="Card Image" />
          </div>
          <h4 className="card-title">Cascade Canyon Trail,</h4>
          <p>
            <strong>Grand Teton, WY</strong> | Length: 13.6 miles
          </p>
          <p className="card-description">
            Description: The Cascade Canyon Trail is a 13.6-mile round-trip long
            hiking trail in Grand Teton National Park.
          </p>
        </div>
        <div className="card">
          <div className="box-img">
            <img src={ImgCard1} alt="Card Image" />
          </div>
          <h4 className="card-title">Colorado Trail,</h4>
          <p>
            <strong>Denver, CO</strong> | Length: 486 miles
          </p>
          <p className="card-description">
            Description: The Colorado Trail is a long-distance trail running for
            486 miles.
          </p>
        </div>
        <div className="card">
          <div className="box-img">
            <img src={ImgCard2} alt="Card Image" />
          </div>
          <h4 className="card-title">Cascade Canyon Trail,</h4>
          <p>
            <strong>Grand Teton, WY</strong> | Length: 13.6 miles
          </p>
          <p className="card-description">
            Description: The Cascade Canyon Trail is a 13.6-mile round-trip long
            hiking trail in Grand Teton National Park.
          </p>
        </div>
      </div>
      <Pagination className="pagination" count={10} />
    </div>
  );
};

export default Sites;
