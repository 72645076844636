import React from "react";
import Button from "@mui/material/Button";
import CampingIcon from "../../assets/icons/Membership/Camping.svg";
import LogoIcon from "../../assets/icons/Membership/Logo.svg";
import BarnIcon from "../../assets/icons/Membership/Barn.svg";
import ContactIcon from "../../assets/icons/Membership/Contacts.svg";
import BreadcrumbIcon from "../../assets/icons/Membership/Breadcrumb.svg";
import Image1 from "../../assets/images/Membership/image-1.jpg";
import Image2 from "../../assets/images/Membership/image-2.jpg";
import Image3 from "../../assets/images/Membership/image-3.jpg";
import Trail1 from "../../assets/images/Membership/trail-1.svg";
import Trail2 from "../../assets/images/Membership/trail-2.svg";
import Trail3 from "../../assets/images/Membership/trail-3.svg";

import "./index.scss";

const MemberShip = () => {
  return (
    <div id="membership-page">
      <div className="first-section">
        <img src={Trail1} className="trail trail-1" alt="trail" />
        <div className="container-content">
          <h1 className="title-section">Membership</h1>
          <p className="text-section">
            Join an association today and enjoy TrailChat’s hands-free
            turn-by-turn on exclusive association trails.
          </p>
          <div className="items-section">
            <div className="item">
              <div className="item-img">
                <img src={CampingIcon} alt="Camping" />
              </div>
              <p className="item-title">Enjoy Exclusive Trails</p>
              <p className="item-text">
                Join an association and enjoy trekking exclusive trails.
              </p>
            </div>

            <div className="item">
              <div className="item-img">
                <img src={LogoIcon} alt="Camping" />
              </div>
              <p className="item-title">Enjoy Nature with Safety</p>
              <p className="item-text">
                Associations provide safe trails to members.
              </p>
            </div>

            <div className="item">
              <div className="item-img">
                <img src={BarnIcon} alt="Camping" />
              </div>
              <p className="item-title">Enjoy it Year-Round</p>
              <p className="item-text">
                Purchase annual memberships and enjoy your trails all year long.
              </p>
            </div>
          </div>
        </div>
        <div className="container-image">
          <img src={Image1} alt="Membership" />
        </div>
      </div>

      <div className="second-section">
        <img src={Trail2} className="trail trail-2" alt="trail" />
        <img src={Trail3} className="trail trail-3" alt="trail" />
        <div className="container-image">
          <img src={Image2} alt="Membership" />
        </div>
        <div className="container-content">
          <h1 className="title-content">Find the Most Exclusive Trails</h1>
          <p className="text-section">
            Gain access to exclusive private trails that are not available to
            the public. Simply purchase an association’s annual or daily
            membership, upload your waivers, documents, and contact information
            and you are ready to go!
          </p>
          <Button variant="contained" className="btn contained">
            Sign Membership
          </Button>
        </div>
      </div>

      <div className="third-section">
        <div className="container-content">
          <h1 className="title-content">Enjoy with Safety</h1>
          <p className="text-section">
            These exclusive trails can be accessed by you using our hands-free
            turn-by-turn direction platform. Never get lost again!
          </p>
          <div className="items-section">
            <div className="item">
              <div className="item-img">
                <img src={ContactIcon} alt="Camping" />
              </div>
              <p className="item-title">Emergency Contacts</p>
              <p className="item-text">
                Invite your emergency contacts to remotely monitor your steps in
                real time.
              </p>
            </div>

            <div className="item">
              <div className="item-img">
                <img src={BreadcrumbIcon} alt="Camping" />
              </div>
              <p className="item-title">Breadcrumbs</p>
              <p className="item-text">
                Backtrack your steps so you can turn around back to safety.
              </p>
            </div>
          </div>
          <Button variant="contained" className="btn btn-safety">
            Sign Up Today
          </Button>
        </div>
        <div className="container-image">
          <img src={Image3} alt="Membership" />
        </div>
      </div>
    </div>
  );
};

export default MemberShip;
