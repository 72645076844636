import React from "react";

import Hero from "./Hero";
import CampingFooter from "./CampingFooter";
import EventsCalendar from "./EventsCalendar";

const Events = () => {
  return (
    <div id="events-page">
      <Hero />
      <EventsCalendar />
      <CampingFooter />
    </div>
  );
};

export default Events;
