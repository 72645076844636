import React from "react";

import Hero from "./Hero";
import Features from "./Features";
import Sites from "./Sites";
import CampingFooter from "./CampingFooter";
import "./index.scss";

const Camping = () => {
  return (
    <div id="camping-page">
      <Hero />
      <Features />
      <Sites />
      <CampingFooter />
    </div>
  );
};

export default Camping;
