import React, { useState, FormEvent } from "react";

import Button from "@mui/material/Button";

import "./index.scss";

const Hero = () => {
  return (
    <div id="hero-camping">
      <div className="box-info">
        <h1 className="hero-title">Jardim Parque dos Eucaliptos,</h1>
        <h2 className="hero-subtitle">
          São Carlos, São Paulo <span className="divider" />
          <span>Length: 6.8 miles</span>
        </h2>
        <p className="description">
          Description: A pretty place surrounded by fragrant pine trees and
          graced by beautifull sunsets.{" "}
        </p>
      </div>
    </div>
  );
};

export default Hero;
