import React from "react";

import Button from "@mui/material/Button";
import ClockIcon from "../../../assets/icons/Events/Clock.svg";
import LocIcon from "../../../assets/icons/Events/Location.svg";

import "./index.scss";

const EventsCalendar = () => {
  return (
    <div className="events-calendar">
      <div className="section-filter">
        <h3 className="title-filter">Modalities</h3>
        <div className="items-filter">
          <div className="item-filter">
            <span className="icon-item" />
            <p className="title-item">Mountain Bike</p>
          </div>
          <div className="item-filter">
            <span className="icon-item item-snow" />
            <p className="title-item">Snowmobile</p>
          </div>
          <div className="item-filter">
            <span className="icon-item item-horse" />
            <p className="title-item">Horse Riding</p>
          </div>
          <div className="item-filter">
            <span className="icon-item item-hiking" />
            <p className="title-item">Hiking</p>
          </div>
          <div className="item-filter">
            <span className="icon-item item-running" />
            <p className="title-item">Trail Run</p>
          </div>
          <div className="item-filter">
            <span className="icon-item item-shotting" />
            <p className="title-item">Hunting</p>
          </div>
        </div>
      </div>
      <div className="section-filter">
        <h3 className="title-filter">Events</h3>
        <div className="items-filter">
          <div className="item-filter">
            <span className="icon-item item-hunter" />
            <p className="title-item">Hunter Paces</p>
          </div>
          <div className="item-filter">
            <span className="icon-item item-polo" />
            <p className="title-item">Polo Matches</p>
          </div>
          <div className="item-filter">
            <span className="icon-item item-clinics" />
            <p className="title-item">Clinics</p>
          </div>
        </div>
      </div>
      <div className="section-calendar">
        <h2 className="title-calendar">October</h2>
        <div className="item-calendar">
          <div className="day-calendar">15</div>
          <div className="content-calendar">
            <p className="title-content">Charity Opening Match</p>
            <p className="text-calendar">
              <img src={LocIcon} alt="text-icon" className="text-icon" /> Rua
              José Gatti, Parque dos Eucaliptos, Pirassununga, SP
            </p>
            <p className="text-calendar">
              <img src={ClockIcon} alt="text-icon" className="text-icon" />
              08:00am - 02:00pm
            </p>
            <p className="text-calendar">
              <span>Modality:</span> Horse Riding
              <span>Event:</span> Polo Matches
            </p>
          </div>
          <div className="register-calendar">
            <Button variant="outlined" className="btn btn-register">
              Register
            </Button>
          </div>
        </div>
        <div className="item-calendar">
          <div className="day-calendar">15</div>
          <div className="content-calendar">
            <p className="title-content">Charity Opening Match</p>
            <p className="text-calendar">
              <img src={LocIcon} alt="text-icon" className="text-icon" /> Rua
              José Gatti, Parque dos Eucaliptos, Pirassununga, SP
            </p>
            <p className="text-calendar">
              <img src={ClockIcon} alt="text-icon" className="text-icon" />
              08:00am - 02:00pm
            </p>
            <p className="text-calendar">
              <span>Modality:</span> Horse Riding
              <span>Event:</span> Polo Matches
            </p>
          </div>
          <div className="register-calendar">
            <Button variant="outlined" className="btn btn-register">
              Register
            </Button>
          </div>
        </div>
        <h2 className="title-calendar">November</h2>
        <div className="item-calendar">
          <div className="day-calendar">15</div>
          <div className="content-calendar">
            <p className="title-content">Charity Opening Match</p>
            <p className="text-calendar">
              <img src={LocIcon} alt="text-icon" className="text-icon" /> Rua
              José Gatti, Parque dos Eucaliptos, Pirassununga, SP
            </p>
            <p className="text-calendar">
              <img src={ClockIcon} alt="text-icon" className="text-icon" />
              08:00am - 02:00pm
            </p>
            <p className="text-calendar">
              <span>Modality:</span> Horse Riding
              <span>Event:</span> Polo Matches
            </p>
          </div>
          <div className="register-calendar">
            <Button variant="outlined" className="btn btn-register">
              Register
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsCalendar;
