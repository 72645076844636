import React, { useState, FormEvent } from "react";

import Button from "@mui/material/Button";
import SoundIcon from "../../../assets/icons/Associations/sound.svg";
import ExclIcon from "../../../assets/icons/Associations/exclamation.svg";
import DocIcon from "../../../assets/icons/Associations/doc.svg";

import "./index.scss";

const Connect = () => {
  return (
    <div id="connect-associations">
      <p className="title">Connect with TrailChat’s Users</p>
      <p className="text">
        TrailChat users will be able to purchase daily or annual memberships,
        which provides your organization with a greater revenue stream.
        TrailChat users will upload their registration information, waivers, and
        proof of Negative Coggins. <br />
        <br />
        Share the best trails with your new members only and feel secure that
        they will not get lost out on your trail system!
      </p>
      <div className="items">
        <div className="item">
          <img src={SoundIcon} alt="icon-item" />
          <p className="text-item">
            Users upload requisite information, pay membership fees, and have
            hands-free voice-directed access to your exquisite private trails!
          </p>
        </div>
        <div className="item">
          <img src={ExclIcon} alt="icon-item" />
          <p className="text-item">
            Upload your exclusive members-only trails and courses for
            turn-by-turn directions for TrailChat’s users.
          </p>
        </div>
        <div className="item">
          <img src={DocIcon} alt="icon-item" />
          <p className="text-item">
            Associations simply upload their forms for membership registration,
            waivers and requests for any required documents.
          </p>
        </div>
      </div>
      <div className="actions">
        <Button className="btn-contained" variant="contained">
          Associate
        </Button>
        <span>or</span>
        <Button className="btn-outlined" variant="outlined">Share</Button>
      </div>
    </div>
  );
};

export default Connect;
