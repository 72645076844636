import React from "react";

import Hero from "./Hero";
import Connect from "./Connect";
import Sites from "./Sites";
import "./index.scss";

const Camping = () => {
  return (
    <div id="camping-page">
      <Hero />
      <Connect />
      <Sites />
    </div>
  );
};

export default Camping;
