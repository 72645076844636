import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import LogoWhite from "../../assets/images/logo-white.svg";
import "./index.scss";

const Header = () => {
  const [headerTransparent, setHeaderTransparent] =
    useState<string>("header-transparent");
  const [menuVisible, setMenuVisible] = useState<string>("");
  const [isWelcomePage, setIsWelcomePage] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.location.pathname === "/welcome" ||
      window.location.pathname === "/claim-offer"
    ) {
      setIsWelcomePage(true);
    } else {
      setIsWelcomePage(false);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/membership") {
      setHeaderTransparent("");
    }
  }, []);

  window.onscroll = function () {
    if (
      window.pageYOffset === 0 &&
      window.location.pathname !== "/membership"
    ) {
      setHeaderTransparent("header-transparent");
    } else {
      setHeaderTransparent("");
    }
  };

  return (
    <header id="header" className={headerTransparent}>
      {/* <div className="launch-date">
        <p>Launching on October 25th</p>
      </div> */}
      <nav className="header-nav">
        <ul className={`links-nav links-nav-${isWelcomePage}`}>
          <a href="/#membership" className="link-nav">
            Members
          </a>
          <a href="/#associations" className="link-nav">
            Associations
          </a>
          <a href="/#" className="link-nav">
            New User
          </a>
        </ul>
        <a href="/#" className="box-logo">
          <img src={LogoWhite} alt="Header Logo" />
        </a>
        <ul className={`links-nav links-nav-${isWelcomePage}`}>
          <a href="/#events" className="link-nav">
            Events
          </a>
          <a href="/#camping" className="link-nav">
            Camping
          </a>
          {/* <a href="#" className="link-nav">Login</a> */}
          <a href="/subscribe" className="link-nav link-sub">
            Claim Offer
          </a>
        </ul>
      </nav>
      <nav className="nav-mobile">
        {isWelcomePage ? (
          <div className="icon-menu" />
        ) : (
          <MenuIcon
            className="icon-menu"
            onClick={() => setMenuVisible("menu-active")}
          />
        )}
        <a href="/" className="box-logo">
          <img src={LogoWhite} alt="Header Logo" />
        </a>
        <div className="icon-menu" />
        <div className={`menu-mobile " ${menuVisible}`}>
          <div className="header-menu">
            <a href="/#" className="box-logo">
              <img src={LogoWhite} alt="Header Logo" />
            </a>
            <CloseIcon
              className="close-icon"
              onClick={() => setMenuVisible("")}
            />
          </div>
          <ul className="links-nav" onClick={() => setMenuVisible("")}>
            <a href="/#membership" className="link-nav">
              Members
            </a>
            <a href="/#associations" className="link-nav">
              Associations
            </a>
            <a href="/#" className="link-nav">
              New User
            </a>
            <a href="/#events" className="link-nav">
              Events
            </a>
            <a href="/#camping" className="link-nav">
              Camping
            </a>
            {/* <a href="#" className="link-nav">Login</a> */}
            <a href="/subscribe" className="link-nav link-sub">
              Subscribe
            </a>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
