import React, { useState, FormEvent } from "react";
import Button from "@mui/material/Button";

import TrailWhiteLeft from "../../../assets/icons/Home/MailForm/trail-white-left.svg";
import TrailWhiteRight1 from "../../../assets/icons/Home/MailForm/trail-white-right-1.svg";
import TrailWhiteRight2 from "../../../assets/icons/Home/MailForm/trail-white-right-2.svg";

import "./index.scss";

const CampingFooter = () => {
  return (
    <div id="camping-footer">
      <div className="content">
        <h2 className="form-title">
          Bring Your Equine friends to your next adventure
        </h2>
        <p className="form-subtitle">
          Search for the perfect camping site, or share your camping events with
          your community!
        </p>
      </div>
      <div className="box-btns">
        <Button className="hero-btn btn-contained" variant="contained">
          Search
        </Button>
        <p className="btns-text">or</p>
        <Button className="hero-btn" variant="outlined">
          Register
        </Button>
      </div>
      <img className="trail trail-left" src={TrailWhiteLeft} alt="Trail" />
      <img className="trail trail-right-1" src={TrailWhiteRight1} alt="Trail" />
      <img className="trail trail-right-2" src={TrailWhiteRight2} alt="Trail" />
    </div>
  );
};

export default CampingFooter;
