import React, { useEffect, useState } from "react";

import AppButton from '../../../components/Button';

import TrailLeft from '../../../assets/icons/Home/Membership/trail-left.svg';
import TrailRight from '../../../assets/icons/Home/Membership/trail-right.svg';
import RideIcon from '../../../assets/icons/Home/Membership/ride.svg';
import LogoIcon from '../../../assets/icons/Home/Membership/logo.svg';
import EnjoyIcon from '../../../assets/icons/Home/Membership/enjoy.svg';
import './index.scss';


const Membership = () => {
    return (
        <div id="membership">
            <h2 className="section-title">Membership</h2>
            <h3 className="section-subtitle">Register Your Association and add new Members!</h3>
            <div className="items">
                <div className="item">
                    <div className="box-icon">
                        <img src={RideIcon} />
                    </div>
                    <h4 className="item-title">Enjoy Exclusive Trails</h4>
                    <p className="item-text">Join an association and enjoy trekking exclusive trails.</p>
                </div>
                <div className="item">
                    <div className="box-icon">
                        <img src={LogoIcon} />
                    </div>
                    <h4 className="item-title">Enjoy Nature with Safety</h4>
                    <p className="item-text">Emergency contacts can watch your trail ride in real-time.</p>
                </div>
                <div className="item">
                    <div className="box-icon">
                        <img src={EnjoyIcon} />
                    </div>
                    <h4 className="item-title">Enjoy it Year-Round</h4>
                    <p className="item-text">Purchase annual memberships and enjoy your trails all year long.</p>
                </div>
            </div>
            <div className="box-btn">
                <AppButton outlined={true} label="Sign Up Today" />
            </div>
            <img className="trail trail-left" src={TrailLeft}/>
            <img className="trail trail-right" src={TrailRight}/>
        </div>
    );
};

export default Membership;