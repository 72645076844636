import React, { useState, FormEvent } from "react";

import Button from "@mui/material/Button";

import "./index.scss";

const Hero = () => {
  return (
    <div id="hero-camping">
      <div className="box-info">
        <h1 className="hero-title">Camping</h1>
        <h2 className="hero-subtitle">
          Want to camp or “glamp” with your horse?
          <br />
          <br /> Find a camping site near your destination when traveling with
          your equine friends!
        </h2>
        <div className="box-btns">
          <Button className="hero-btn btn-contained" variant="contained">
            Search
          </Button>
          <p className="btns-text">or</p>
          <Button className="hero-btn" variant="outlined">
            Register
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
