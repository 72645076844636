import React, { useState, FormEvent } from "react";

import Button from "@mui/material/Button";
import FeaturesImg from "../../../assets/images/Camping/features.jpg";
import HorseIcon from "../../../assets/icons/Camping/horse.svg";
import Houseicon from "../../../assets/icons/Camping/house.svg";
import PersonIcon from "../../../assets/icons/Camping/person.svg";
import TrailLeft1 from "../../../assets/images/Camping/trail-left-1.svg";
import TrailLeft2 from "../../../assets/images/Camping/trail-left-2.svg";
import TrailRight1 from "../../../assets/images/Camping/trail-right-1.svg";
import TrailRight2 from "../../../assets/images/Camping/trail-right-2.svg";

import "./index.scss";

const Features = () => {
  return (
    <div id="features-camping">
      <div className="section-info">
        <div className="box-info">
          <p className="title">Enjoy the Features</p>
          <p className="info">
            Proprietors upload information on their equine-friendly campgrounds
            and facilities for our TrailChatters use. No need to comb through
            individual websites and friends' suggestions when you utilize our
            TrailGrounds feature!
            <br />
            <br />
            The ease of all of the facilities in one place.
          </p>
        </div>
        <div className="box-img">
          <img src={FeaturesImg} alt="Features img" />
        </div>
      </div>
      <div className="section-items">
        <div className="item">
          <div className="card-item">
            <img src={HorseIcon} alt="Features Icon" />
          </div>
          <p className="text-item">Reach a wide audience of horseback riders</p>
        </div>
        <div className="item">
          <div className="card-item">
            <img src={PersonIcon} alt="Features Icon" />
          </div>
          <p className="text-item">
            Easily upload your information to a wider audience
          </p>
        </div>
        <div className="item">
          <div className="card-item">
            <img src={Houseicon} alt="Features Icon" />
          </div>
          <p className="text-item">
            Showcase the best features of your campsite
          </p>
        </div>
      </div>
      <div className="box-btn">
        <Button className="features-btn" variant="contained">
          Register
        </Button>
      </div>
      <img className="trail trail-left-1" src={TrailLeft1} alt="Trail" />
      <img className="trail trail-left-2" src={TrailLeft2} alt="Trail" />
      <img className="trail trail-right-1" src={TrailRight1} alt="Trail" />
      <img className="trail trail-right-2" src={TrailRight2} alt="Trail" />
    </div>
  );
};

export default Features;
