import { useParams } from "react-router-dom";
import "./index.scss";

const TrailDetails = () => {
  const { trailId } = useParams();

  return (
    <div id="trail-details">
      <h1>You will be redirected to see this amazing trail</h1>
      <p>If you didn't get redirected</p>
      <a
        className="trail-details-btn"
        href={`trailchat://trail/details/${trailId}`}
      >
        Go to app
      </a>
    </div>
  );
};

export default TrailDetails;
