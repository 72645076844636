import React, { useEffect, useState } from "react";
import AppButton from '../../../components/Button';

import './index.scss'

const Events = () => {
  return (
    <div id="events">
      <div className="content">
        <div className="content-header">
          <h3 className="title-events">Events</h3>
          <span className="soon-text">Coming Soon</span>
        </div>
        <p className="text-events">Register Your Event in TrailChat’s Exclusive Events Calendar.</p>
        <p className="include-text">Includes:</p>
        <ul>
          <li>Hunter Paces</li>
          <li>Endurance Races</li>
          <li>Clinics</li>
          <li>Shows</li>
          <li>Demonstrations</li>
          <li>Polo Matches</li>
        </ul>
        <AppButton label="Search Events" outlined={false} />
      </div>
    </div>
  );
};

export default Events;