import React from "react";

import Swal from "sweetalert2";
import axios from "axios";

// const subscribeEmail = async (email: string) => {
//   try {
//     const response = await axios({
//       method: "POST",
//       url: "https://trail-chat-api-staging.herokuapp.com/api/capture-email",
//       data: { email: email },
//     });
//     window.location.href = "/thank-you";
//     return response;
//   } catch (error) {
//     Swal.fire({
//       title: "Error:",
//       text: "There was an error sending the email",
//       icon: "error",
//       confirmButtonText: "OK",
//       confirmButtonColor: "#1944d2",
//     });
//     return error;
//   }
// };

const subscribeEmail = async (email: string, setLoading: Function) => {
  setLoading(true);
  await axios
    .post("https://trail-chat-api-staging.herokuapp.com/api/capture-email", {
      email: email,
    })
    .then(() => {
      window.location.href = "/thank-you";
    })
    .catch((error) => {
      Swal.fire({
        title: "Error:",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#1944d2",
      });
      console.log(error);
    });
  setLoading(false);
};

export { subscribeEmail };
