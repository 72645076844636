import React, { useState, FormEvent } from "react";

import Button from "@mui/material/Button";

import "./index.scss";

const Hero = () => {
  return (
    <div id="hero-events">
      <div className="box-info">
        <h1 className="hero-title">Events</h1>
        <h2 className="hero-subtitle">
          For all TrailChatters searching for their next adventure.
        </h2>
        <div className="hero-btns">
          <Button variant="contained" className="btn contained">
            Search
          </Button>
          <span>or</span>
          <Button variant="outlined" className="btn outlined">
            Register
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
