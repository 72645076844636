import React, { useState, FormEvent } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import TrailWhiteLeft from "../../../assets/icons/Home/MailForm/trail-white-left.svg";
import TrailWhiteRight1 from "../../../assets/icons/Home/MailForm/trail-white-right-1.svg";
import TrailWhiteRight2 from "../../../assets/icons/Home/MailForm/trail-white-right-2.svg";

import "./index.scss";
import { subscribeEmail } from "../../../api/subscribe";
import CircularProgress from "@mui/material/CircularProgress";

const MailForm = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async (e: any) => {
    e.preventDefault();
    subscribeEmail(email, setLoading);
  };

  return (
    <div id="mail-form">
      <div className="content">
        <h2 className="form-title">Subscribe</h2>
        <p className="form-subtitle">
          Get <span>FREE</span> premium and become a Trailblazer!
        </p>
        <form onSubmit={(e) => submit(e)}>
          <TextField
            required
            className="form-input"
            id="filled-basic"
            placeholder="Enter Your Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            className="form-btn"
            variant="outlined"
            placeholder="Enter Your Email Address"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress className="icon-loading" />
            ) : (
              "Claim Offer"
            )}
          </Button>
        </form>
      </div>
      <img className="trail trail-left" src={TrailWhiteLeft} alt="Trail" />
      <img className="trail trail-right-1" src={TrailWhiteRight1} alt="Trail" />
      <img className="trail trail-right-2" src={TrailWhiteRight2} alt="Trail" />
    </div>
  );
};

export default MailForm;
