import Hero from "./Hero";
import HowItWorks from "./How It Works";
import SafetyFeatures from "./SafetyFeatures";
import MailForm from "./MailForm";
import { Helmet } from "react-helmet";

import "./index.scss";

const Welcome = () => {
  return (
    <div id="welcome">
      <Helmet>
        <script>
          {`
                        (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:3183994,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `}
        </script>
      </Helmet>
      <Hero />
      <SafetyFeatures />
      <HowItWorks />
      <MailForm />
    </div>
  );
};

export default Welcome;
