import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../pages/Home";
import Welcome from "../pages/Welcome";
import Subscribe from "../pages/Subscribe";
import ThankYou from "../pages/ThankYou";
import Camping from "../pages/Camping";
import ClaimOffer from "../pages/ClaimOffer";
import TrailDetails from "../pages/TrailDetails";
import Associations from "../pages/Associations";
import Events from "../pages/Events";
import Membership from "../pages/Membership";

function App() {
  const [isSubscribePage, setIsSubscribePage] = useState<boolean>(false);

  useEffect(() => {
    window.location.pathname === "/subscribe" ||
    window.location.pathname === "/thank-you"
      ? setIsSubscribePage(true)
      : setIsSubscribePage(false);
  }, []);

  return (
    <div id="principal-content">
      <Router>
        {!isSubscribePage && <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/camping" element={<Camping />} />
          <Route path="/claim-offer" element={<ClaimOffer />} />
          <Route path="/trail/details/:trailId" element={<TrailDetails />} />
          <Route path="/associations" element={<Associations />} />
          <Route path="/events" element={<Events />} />
          <Route path="/membership" element={<Membership />} />
        </Routes>
        {!isSubscribePage && <Footer />}
      </Router>
    </div>
  );
}

export default App;
