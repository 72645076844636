import React, { useEffect, useState } from "react";
import AppButton from '../../../components/Button';

import CampingImg from '../../../assets/images/Home/camping.jpg';
import Trail from '../../../assets/icons/Home/Camping/trail.svg';
import TrailLeft from '../../../assets/icons/Home/Camping/trail-left.svg';
import './index.scss'

const Camping = () => {
  return (
    <div id="camping">
      <div className="content">
        <div className="box-img">
          <img src={CampingImg} alt="Camping" />
        </div>
        <div className="content-camping">
          <h3 className="section-title">Camping
            <span className="soon-text">Coming Soon</span>
          </h3>
          <p className="text-camping">Going to a new state and looking for a place to camp with your horse? Stay connected with horse camping facilities all throughout the<br /> country.</p>
          <div className="box-btns">
            <AppButton outlined={true} label="Explore Facilities" />
            <AppButton outlined={true} label="Upload Facilities" />
          </div>
        </div>
      </div>
      <img className="trail trail-left" src={TrailLeft} />
      <img className="trail" src={Trail} />
    </div>
  );
};

export default Camping;