import React from "react";

import Button from '@mui/material/Button';

import './index.scss';


const AppButton = ({ outlined, label }: { outlined: boolean, label: string }) => {
    return (
        <Button className={outlined ? "app-btn btn-outlined" : "app-btn"} variant={outlined ? "outlined" : "contained"}>{label}</Button>
    );
};

export default AppButton;