import React, { useState, FormEvent } from "react";

// import AppleIcon from "@mui/icons-material/Apple";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TextLoop from "react-text-loop";
// import BikeIcon from '../../../assets/icons/Home/Hero/bike.svg';
// import HikersIcon from '../../../assets/icons/Home/Hero/hikers.svg';
// import HuntersIcon from '../../../assets/icons/Home/Hero/hunters.svg';
// import RunnerIcon from '../../../assets/icons/Home/Hero/runner.svg';
// import SnowIcon from '../../../assets/icons/Home/Hero/snow.svg';
// import Horsecon from '../../../assets/icons/Home/Hero/horse.svg';
// import NatureIcon from '../../../assets/icons/Home/Hero/nature.svg';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { subscribeEmail } from "../../../api/subscribe";
import CircularProgress from "@mui/material/CircularProgress";

import "./index.scss";

const Hero = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async (e: any) => {
    e.preventDefault();
    subscribeEmail(email, setLoading);
  };

  return (
    <div id="hero">
      <div className="hero-content">
        <h1 className="hero-title">
          Get Free Access to the Best App for Trail Voice Navigation
        </h1>
        {/* <p className="hero-text">
          The app that guides you turn-by-turn on your favorite trails.
        </p> */}
        <div className="hero-items">
          <p className="hero-text">
            Find and ride the best trails for
            <TextLoop>
              <span className="loop-item">
                {/* <span className="box-icon"><img src={Horsecon} alt="Hero Icon" /></span> */}
                <p className="item-text">Horseback Riders</p>
              </span>
              <span className="loop-item">
                {/* <span className="box-icon"><img src={BikeIcon} alt="Hero Icon" /></span> */}
                <p className="item-text">Mountain Bikers</p>
              </span>
              <span className="loop-item">
                {/* <span className="box-icon"><img src={SnowIcon} alt="Hero Icon" /></span> */}
                <p className="item-text">Snowmobiles</p>
              </span>
              <span className="loop-item">
                {/* <span className="box-icon"><img src={HikersIcon} alt="Hero Icon" /></span> */}
                <p className="item-text">Hikers</p>
              </span>
              <span className="loop-item">
                {/* <span className="box-icon"><img src={RunnerIcon} alt="Hero Icon" /></span> */}
                <p className="item-text">Trail Runners</p>
              </span>
              <span className="loop-item">
                {/* <span className="box-icon"><img src={HuntersIcon} alt="Hero Icon" /></span> */}
                <p className="item-text">Hunters</p>
              </span>
              <span className="loop-item">
                {/* <span className="box-icon"><img src={NatureIcon} alt="Hero Icon" /></span> */}
                <p className="item-text">Nature Lovers</p>
              </span>
            </TextLoop>
          </p>
        </div>
        {/* <div className="hero-btns">
          <Button className="hero-btn" variant="contained" endIcon={<AppleIcon />}>Download IOS</Button>
          <Button className="hero-btn" variant="contained" endIcon={<AndroidIcon />}>Download Android</Button>
        </div> */}
        <p className="hero-text text-premium">
          Get FREE premium and become a Trailblazer!
        </p>
        <form className="hero-form" onSubmit={(e) => submit(e)}>
          <TextField
            required
            className="hero-input"
            id="filled-basic"
            placeholder="Enter Your Email Address"
            variant="outlined"
            value={email}
            size="medium"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            className="hero-btn"
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress className="icon-loading" />
            ) : (
              "Claim Offer"
            )}
          </Button>
        </form>
        <span className="info-text">
          <CheckCircleOutlineIcon />
          No credit card required
        </span>
        <span className="info-text">
          <CheckCircleOutlineIcon />
          Offer Valid Thru January 7th, 2023
        </span>
      </div>
    </div>
  );
};

export default Hero;
