import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import HowItWorks from "./How It Works";
import Associations from "./Associations";
import Membership from "./Membership";
import Events from "./Events";
import Camping from "./Camping";
import MailForm from "./MailForm";
//import HowToUse from './HowToUse';
import SafetyFeatures from "./SafetyFeatures";

import "./index.scss";

const Home = () => {
  return (
    <div id="home">
      <Hero />
      <SafetyFeatures />
      <HowItWorks />
      <Associations />
      <Membership />
      <Events />
      <Camping />
      {/* <HowToUse /> */}
      <MailForm />
    </div>
  );
};

export default Home;
