import React, { useEffect, useState } from "react";

import AppButton from "../../../components/Button";

import IconRecord from "../../../assets/icons/Home/HowItWorks/record.svg";
import IconListen from "../../../assets/icons/Home/HowItWorks/bluetooth.svg";
import IconUpload from "../../../assets/icons/Home/HowItWorks/upload.svg";
import IconArrowHiw from "../../../assets/icons/Home/HowItWorks/arrow-hiw.svg";
import TrailUpperRight1 from "../../../assets/icons/Home/HowItWorks/Trails/upper-right-1.svg";
import TrailUpperRight2 from "../../../assets/icons/Home/HowItWorks/Trails/upper-right-2.svg";
import TrailLowerLeft from "../../../assets/icons/Home/HowItWorks/Trails/lower-left.svg";
import TrailUpperLeft from "../../../assets/icons/Home/HowItWorks/Trails/upper-left.svg";
import "./index.scss";

const HowItWorks = () => {
  return (
    <div id="how-it-works">
      <h2 className="section-title">How TrailChat Works</h2>
      <div className="items">
        <div className="item">
          <div className="item-content">
            <img className="icon" src={IconRecord} />
            <h4 className="item-title">Record your Trail</h4>
          </div>
        </div>
        <div className="box-arrow">
          <img className="arrow-hiw" src={IconArrowHiw} />
        </div>
        <div className="item">
          <div className="item-content">
            <img className="icon icon-upload" src={IconUpload} />
            <h4 className="item-title">Upload your trail</h4>
          </div>
        </div>
        <div className="box-arrow">
          <img className="arrow-hiw" src={IconArrowHiw} />
        </div>
        <div className="item">
          <div className="item-content">
            <img className="icon icon-listen" src={IconListen} />
            <h4 className="item-title">Listen to the directions</h4>
          </div>
        </div>
      </div>
      {/* <div className="box-btn">
                <AppButton label="Explore New Trails" outlined={true} />
            </div> */}
      {/* <img className="trail trail-upper-right-1" src={TrailUpperRight1} />
            <img className="trail trail-upper-right-2" src={TrailUpperRight2} /> */}
      <img className="trail trail-lower-left" src={TrailLowerLeft} />
      {/* <img className="trail trail-upper-left" src={TrailUpperLeft} /> */}
    </div>
  );
};

export default HowItWorks;
